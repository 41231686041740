import React, { useState, useContext, useEffect } from 'react'
import MenuAppBars from '../../components/appBar/appBarr'
import { Grid, Tab, Tabs, Box } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import Recargas from '../../components/flujoSaldo/recargas/recargas';
import DeCajaAMasters from '../../components/flujoSaldo/deCajaAMaster/deCajaAMasters';
import AppContext from '../../contexts/ServiceContext';
import CardStadoSaldo from '../../components/cardStadoSaldo';
import { TITLEPAGE } from '../../contexts/constantesVar';
import DataTableInteresASaldoCaja from '../../components/flujoSaldo/tableInteresASaldo/dataTableInteresASaldoCaja';
import { NavLink, useParams } from 'react-router-dom';

function Filtersss() {
  const { id } = useParams()

  const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones } = useContext(AppContext)

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
    } else {
        window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
    }
    dispatch({
      type: TITLEPAGE,
      payload: "FLUJO DE SALDO"
    })
  }, [])
  return (
    <>
      {acciones.includes('ver_info') ?
        <>
          <CardStadoSaldo />
          <Grid
            bgcolor="backgroundColorPage"
            sx={{
              marginTop: "20px"
            }}
          >
            <TabContext value={`${id}`}>
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Tabs
                  value={`${id}`}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab value={'1'} component={NavLink} to = '/flujo/1' label="Recargas" />
                  <Tab value={'2'} component={NavLink} to = '/flujo/2' label="De caja a master" />
                  <Tab value={'3'} component={NavLink} to = '/flujo/3' label="Intereses a saldo" />
                </Tabs>
              </Box>
              <TabPanel value={'1'} sx={{ paddingInline: "0px" }}><Recargas /></TabPanel>
              <TabPanel value={'2'} sx={{ paddingInline: "0px" }}><DeCajaAMasters /></TabPanel>
              <TabPanel value={'3'} sx={{ paddingInline: "0px" }}><DataTableInteresASaldoCaja /></TabPanel>
            </TabContext>
          </Grid>
        </>
        :
        <></>
      }

    </>
  )
}


export default Filtersss