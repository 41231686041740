import * as React from 'react';
import { useDemoData } from '@mui/x-data-grid-generator';
import { Box, Button, IconButton, Pagination, Stack, Typography, TablePagination, Paper, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Add, Delete, Edit, Search, Visibility } from '@mui/icons-material';
//import { DataGridPro, GridToolbar, esES, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-pro';
import useSWR from "swr"
import SkeletonTable from '../../skelholder/skelethonTable';
import { NavLink } from 'react-router-dom';
import AppContext from '../../../contexts/ServiceContext';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { GetEnviosCajaMaster } from './getEnvios';
import FormUpdateEnvio from './formUpdateEnvio';
import FormDeleteEnvio from './formDeleteEnvio';
import VerFactura from './verFactura';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { es } from 'date-fns/locale'
import { LoadingButton } from '@mui/lab';
import axiosConfigs from '../../axiosConfig';
import ExportExcel from "react-export-excel"
import { DataGrid, GridToolbar, esES, useGridApiRef } from '@mui/x-data-grid';
import FormCancelarEnvioInter from './formDeleteEnvio';
import { GetNigeriaBank } from './getNigeriaBank';


const ExcelFile = ExportExcel.ExcelFile
const ExcelSheet = ExportExcel.ExcelSheet
const ExcelColumn = ExportExcel.ExcelColumn


const VISIBLE_FIELDS = ['name', 'code'];
let total = 0


const arrayMaster = ['Master_GNOB']

export default function DataTableNigeriaBank() {
    //COLUMNAS DE LA TABLA
    const columns1 = [

        {
            field: 'name',
            headerName: 'Nombre del banco',
            type: 'phone',
            width: 300,
            editable: false,
        },
        {
            field: 'code',
            headerName: 'Codigo del banco',
            type: 'phone',
            width: 200,
            editable: false,
        }

    ];

    const [dataArray, setDataArray] = React.useState([])


    const apiRef = useGridApiRef();

    const columns = React.useMemo(
        () => columns1.filter((column) => VISIBLE_FIELDS.includes(column.field)),
        [columns1],
    );

    const { data, error, isLoading, } = useSWR(["getNigeriaBank"], () => GetNigeriaBank(), {})

    console.log(data)

















    if (error) return <SkeletonTable />
    return (
        <div >

            <Grid

                spacing={1}
                bgcolor="backgroundColorPage"
                container
                sx={{ marginBottom: 1 }}
            >

                <Box sx={{ height: '400px', width: '100%' }}>
                    <DataGrid
                        rows={data ? data : []}

                        getRowId={(row) => row.code}
                        apiRef={apiRef}

                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        columns={columns}
                        slots={{
                            toolbar: GridToolbar,

                        }}

                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableRowSelectionOnClick
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },

                        }}

                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    createdAt: false,
                                    fechaA: false

                                },
                            },
                            pagination: { paginationModel: { pageSize: 3 } },
                            aggregation: {
                                model: {
                                    quantSend: 'sum',
                                },
                            },
                        }}
                        pagination={true}
                    />
                </Box>
            </Grid>
        </div>

    )
}


