import React, { useState, useContext, useEffect } from 'react'
import { Grid, Tab, Tabs, Box, InputAdornment, TextField, Autocomplete, Typography, FormControl, InputLabel, Select, MenuItem, Button, Card, CardContent } from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import AppContext from '../../contexts/ServiceContext';
import { useForm } from 'react-hook-form';
import { AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { jsPDF } from "jspdf";
import LinearProgress from '@mui/material/LinearProgress';
import { useSWRConfig } from 'swr';
import CardStadoSaldo from '../../components/cardStadoSaldo';
import { TITLEPAGE, M2U, MCMPART2, MTN, YOOMEE, paises } from '../../contexts/constantesVar';
import { set } from 'date-fns';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function RecibirMoEx() {

    const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones, porcentage, AxiosConfigsToken } = useContext(AppContext)
    const { mutate } = useSWRConfig()
    //el useForm de react form hook
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm();

    const [loadM, setLoadM] = useState(false)//estado para activar el spinner del boton submit
    const [load, setLoad] = useState(false)//estado para activar el spinner del boton submit

    const [idTransaction, setIdTransaction] = useState('')
    const [reference, setReference] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [receiverName, setReceiverName] = useState('')
    const [receiverDocument, setReceiverDocument] = useState('')
    const [amountSend, setAmountSend,] = useState('')

    const [validarTransaciion, setValidarTransaciion] = useState(false)
    const [verificarTransaciion, setVerificarTransaciion] = useState(false)
    const [spinnerVerificar, setSpinnerVerificar] = useState(false)
    const [spinnerValidar, setSpinnerValidar] = useState(false)
    const [llaveSecreta,setLlaveSecreta] = useState('')

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data) => {
        console.log(data)
        setSpinnerVerificar(true)
        setVerificarTransaciion(false)
        setLlaveSecreta(data.llaveSecreta)
        try {
            const recibir = await AxiosConfigsToken({ url: `/me/getTransactionMoEx`, data, method: "post" })

            console.log(recibir)

            if (recibir.data.verificar ) {
                if (recibir.data.data.error.Id.$value === 0) {
                    console.log(recibir.data.data)
                    setReceiverName(recibir.data.data.transaction.Receiver.$value)
                    setAmountSend(recibir.data.data.transaction.AmountSent.$value)
                    //setReceiverDocument(recibir.data.data.transaction.ReceiverIdDocumentNumber.$value)
                    setVerificarTransaciion(false)
                    setSpinnerVerificar(false)
                    handleClickOpen()
                } else {
                    toast.error(`${recibir.data.data.error.Description.$value}`)
                    setVerificarTransaciion(false)
                    setSpinnerVerificar(false)
                }

            } else {
                setVerificarTransaciion(false)
                setSpinnerVerificar(false)

            }

        } catch (error) {
            setVerificarTransaciion(false)
            setSpinnerVerificar(false)        
        }
    }


    const ValidarPago = async () =>{
        setSpinnerValidar(true)
        try {
            if (receiverDocument&&receiverName&&idTransaction&&amountSend) {

                const pagar = await AxiosConfigsToken({ url: `/me/confirmPaymentTransactionMoEx`, data:{
                    receiverDocument,
                    receiverName,
                    idTransaction,
                    amountSend,
                    idAdmin:userId,
                    llaveSecreta

                }, method: "post" })

                if (pagar.data.verificar) {
                    if (pagar.data.data.error.Id.$value === 0) {

                        //console.log(pagar.data.data)
                        toast.success(`El pago se ha realizado correctamente`)
    
                        setIdTransaction('')
                        setReceiverName('')
                        setReceiverDocument('')
    
                        setSpinnerValidar(false)
    
                        handleClose()
    
                    } else {
                        toast.error(`${pagar.data.mens}`)
                        setSpinnerValidar(false)
    
                    }
                } else {
                    toast.error(`${pagar.data.mens}`)
                    setSpinnerValidar(false)
                }

            } else {
                setSpinnerValidar(false)

            }
        } catch (error) {
            setSpinnerValidar(false)

        }
    }



    useEffect(() => {

        if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
        } else {
            window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
        }
        dispatch({
            type: TITLEPAGE,
            payload: "Enviar a nivel internacional"
        })
    }, [])

    return (
        <>
            {valideLogin ?
                <>
                    <div >

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >

                            <DialogContent sx={{ paddingInline: 1, paddingTop: 4 }}>
                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Nombre del receptor"
                                        id="outlined-size-small-name-s"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        value={receiverName}
                                        onChange={(e) => { setReceiverName(e.target.value) }}

                                    //{...register("receiverName", { required: "Campo requerido", minLength: 4 })}
                                    //error={!!errors?.receiverName}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="DIP/Pasaporte del receptor"
                                        id="outlined-size-small"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        value={receiverDocument}
                                        onChange={(e) => { setReceiverDocument(e.target.value) }}

                                    //{...register("receiverDocument", { required: "Campo requerido", minLength: 4 })}
                                    //error={!!errors?.receiverDocument}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Id de la transaccion"
                                        id="outlined-size-small"
                                        size="medium"
                                        value={idTransaction}
                                        onChange={(e) => { setIdTransaction(e.target.value) }}
                                        sx={{ width: "100%" }}

                                    //{...register("dipSend", { required: "Campo requerido", minLength: 4 })}
                                    //error={!!errors?.dipSend}

                                    />
                                </div>


                                <DialogContentText sx={{
                                    width: {
                                        xs: "95%",
                                        sm: "95%",
                                        md: 400,
                                        lg: 400,
                                        xl: 400,
                                    },
                                    paddingBlock: 2,
                                }} id="alert-dialog-description">

                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>

                                <LoadingButton
                                    loading={spinnerValidar}
                                    variant="outlined"
                                    color="primary"
                                    //sx={{ width: "100%" }}
                                    size="large"
                                    onClick={() => { ValidarPago() }}

                                >
                                    <span>Continuar</span>
                                </LoadingButton>

                            </DialogActions>

                        </Dialog>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
                        <Grid
                            spacing={1}
                            bgcolor="backgroundColorPage"
                            sx={{display:'flex',justifyContent:"center"}}
                            container
                        >

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                                

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Referencia de la transaccion"
                                        id="outlined-size-small-name-s"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        {...register("reference", { required: "Campo requerido" })}
                                        error={!!errors?.reference}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Llave secreta"
                                        id="outlined-size-small-name-s"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        {...register("llaveSecreta", { required: "Campo requerido"})}
                                        error={!!errors?.llaveSecreta}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 20, justifyContent: 'space-around', display: 'flex' }}>
                                        <LoadingButton
                                            loading={spinnerVerificar}
                                            variant="contained"
                                            //color="success"
                                            sx={{ width: "100%" }}
                                            size="large"
                                            type='submit'
                                        >
                                            <span>Verificar transaccion y pagar</span>
                                        </LoadingButton>
                                    </div>
                            </Grid>
                            
                        </Grid>
                    </form>
                </>
                :
                <></>
            }

        </>
    )
}


export default RecibirMoEx