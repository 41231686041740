import React, { useState, useContext, useEffect } from 'react'
import MenuAppBars from '../../components/appBar/appBarr'
import { Grid, Tab, Tabs, Box, InputAdornment, TextField, Autocomplete, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import Envios from '../../components/transacciones/envios/envios';
import Recepciones from '../../components/transacciones/recepciones/recepciones';
import AppContext from '../../contexts/ServiceContext';
import { useForm } from 'react-hook-form';
import { AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { jsPDF } from "jspdf";
import { useSWRConfig } from 'swr';

import axiosConfigs from '../../components/axiosConfig';
import CardStadoSaldo from '../../components/cardStadoSaldo';
import { TITLEPAGE } from '../../contexts/constantesVar';


function Recibir() {
    const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones, AxiosConfigsToken } = useContext(AppContext)
    const { mutate } = useSWRConfig()

    const [enviosBuscados, setEnviosBuscados] = useState([]);
    const [cargaEnvio, setCargaEnvio] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadP, setLoadP] = useState(false);
    const [nameSend, setNameSend] = useState('');
    const [phoneSend, setPhoneSend] = useState('');
    const [llaveSecreta,setLlaveSecreta] = useState('')
    const [llaveSecretaB,setLlaveSecretaB] = useState(true)
    const [quantSend, setQuantSend] = useState('');
    const [dipSend, setDipSend] = useState('');
    const [phoneRecep, setPhoneRecep] = useState('');
    const [adressRecep, setAdressRecep] = useState('');
    const [adressSend, setAdressSend] = useState('');
    const [nameRecep, setNameRecep] = useState('');
    const [codigoVerif, setCodigoVerif] = useState('');
    const [dipRecep, setDipRecep] = useState('');
    const [formR, setFormR] = useState(false);
    const [idTransfert, setIdTransfert] = useState("")



    const doc = new jsPDF("p", "pt", "b6");

    const GenerarPdf = async () => {
        //console.log("first")
        var content = document.querySelector("#facturaSend")
        await doc.html(content, {
            callback: (pdf) => {
                pdf.save("factura.pdf")
            }
        });
    }

    //el useForm de react form hook
    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors }
    } = useForm();





    const onSubmit = async (data) => {

        data.idAdmin = userId
        data.userName = userName
        //data.llave = llaveSecreta

        if (userId && userName) {

            try {
                setLoad(true)
                const envio = await AxiosConfigsToken({ url: `/verificarEnvio`, method: "post", data })

                if (envio.data.verificar && envio.data.data.docs[0]) {

                    setLoad(false)
                    setAdressRecep(envio.data.data.docs[0].adressRecep)
                    setAdressSend(envio.data.data.docs[0].adressAdmin)
                    setNameSend(envio.data.data.docs[0].nameSend)
                    setPhoneSend(envio.data.data.docs[0].phoneSend)
                    setDipSend(envio.data.data.docs[0].dipSend)
                    setQuantSend(envio.data.data.docs[0].quantSend)
                    setPhoneRecep(envio.data.data.docs[0].phoneRecep)
                    setNameRecep(envio.data.data.docs[0].nameRecep)
                    setCodigoVerif(envio.data.data.docs[0].codeRecp)
                    setIdTransfert(envio.data.data.docs[0]._id)
                    setFormR(true)
                } else {
                    setFormR(false)
                    setLoad(false)
                    toast.error(`${envio.data.mens}`)
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            const mes = Number(new Date(envio.data.data.fechaA).getMonth()) + 1;
                            const dia = Number(new Date(envio.data.data.fechaA).getDate());
                            const agno = Number(new Date(envio.data.data.fechaA).getFullYear());
                            const hora = new Date(envio.data.data.fechaA).getHours();
                            const min = new Date(envio.data.data.fechaA).getMinutes();

                            const fecha = dia + '/' + mes + '/' + agno + '  ' + hora + ':' + min;
                            return (

                                <div className='container-dialog-confirm' >
                                    <div id='facturaSendzzz' style={{ marginLeft: 10, marginTop: 20 }} >
                                        <Typography variant='h6' sx={{ color: "#dd2c00" }}>
                                            El envio ya se ha cobrado
                                        </Typography>

                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Fecha de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{fecha}</span></p>
                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Ciudad de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.data.adressRecep}</span></p>
                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Punto de pago:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envio.data.data.adressGettoRecep}</span></p>
                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, color: "#000000", fontWeight: '700' }}>Tel.. del Agente:</span> <span style={{ fontWeight: "700", color: "#000000", fontSize: 14 }}>{envio.data.data.phoneAdminRecep}</span></p>
                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, color: "#000000", fontWeight: '700' }}>Nombre del receptor:</span> <span style={{ fontWeight: "700", color: "#000000", fontSize: 14 }}>{envio.data.data.nameRecep}</span></p>
                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, color: "#000000", fontWeight: '700' }}>Tel. del receptor:</span> <span style={{ fontWeight: "700", color: "#000000", fontSize: 14 }}>{envio.data.data.phoneRecep}</span></p>
                                        <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, color: "#000000", fontWeight: '700' }}>Dip/Pass. del receptor:</span> <span style={{ fontWeight: "700", color: "#000000", fontSize: 14 }}>{envio.data.data.dipRecep}</span></p>
                                    </div>
                                    <div style={{ marginLeft: 10 }} >
                                        <Button
                                            size='small'
                                            variant="contained"
                                            onClick={onClose}>
                                            Cerrar
                                        </Button>

                                    </div>
                                </div>
                            );
                        },

                    });
                }

            } catch (error) {
                //console.log(error)
                setFormR(false)
                setLoad(false)
                toast.error(`Hay un problemaddd`)

            }

        } else {

        }


    }


    const Recibir = async () => {
        if (dipRecep) {
            try {
                setLoadP(true)
                const envio = await AxiosConfigsToken({
                    url: `/recibir`, method: "post",
                    data: {
                        adressRecep,
                        nameRecep,
                        nameSend,
                        dipRecep,
                        dipSend,
                        quantRep: quantSend,
                        codeRecp: codigoVerif,
                        nameAdmin: userName,
                        phoneRecep,
                        phoneSend,
                        idTransfert,
                        idAdmin: userId,
                    }
                })

                if (envio.data.verificar) {
                    confirmAlert({
                        customUI: ({ onClose }) => {
                            const mes = Number(new Date(envio.data.result.fechaA).getMonth()) + 1;
                            const dia = Number(new Date(envio.data.result.fechaA).getDate());
                            const agno = Number(new Date(envio.data.result.fechaA).getFullYear());
                            const hora = new Date(envio.data.result.fechaA).getHours();
                            const min = new Date(envio.data.result.fechaA).getMinutes();

                            const fecha = dia + '/' + mes + '/' + agno + '  ' + hora + ':' + min;
                            return (

                                <div className='container-dialog-confirm' >
                                    <div id='facturaSend' style={{ marginLeft: 10, marginTop: 0 }} >
                                        
                                        <h3 style={{ marginBlock: -7 }}>FACTURA DE RECEPCION</h3>

                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Fecha de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{fecha}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Ciudad de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.adressRecep}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Punto de pago:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envio.data.result.adressGettoRecep}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Tel.. del Agente:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.phoneAdminRecep}</span></p>
                                        <h4 style={{ marginBlock: -6, marginTop: 3, color: '#000000', width: "100%" }}>DATOS DEL BENEFICIARIO</h4>

                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Nombre del receptor:</span> <span style={{ fontWeight: "700", fontSize: 12 }}>{envio.data.result.nameRecep}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Tel. del receptor:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.phoneRecep}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>DIP/Pass del receptor:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.dipRecep}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Cantidad a recibir:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{Number(envio.data.result.quantSend).toLocaleString("es-GQ")} XAF</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Codigo de envio:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.codeVerificacion}</span></p>


                                        <h4 style={{ marginBlock: -6, marginTop: 3, color: '#000000', width: "100%" }}>DATOS DEL REMITENTE</h4>

                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Ciudad de envio:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.adressSend}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Nombre del remitente:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.nameSend}</span></p>
                                        <p style={{ marginBlock: 1, width: "100%" }}><span style={{ fontSize: 12, color: '#000000', fontWeight: '700' }}>Tel. del remitente:</span> <span style={{ fontWeight: "700", fontSize: 12, color: '#000000' }}>{envio.data.result.phoneSend}</span></p>

                                    </div>
                                    <div style={{ marginLeft: 10 }} >

                                        <Button
                                            size='small'
                                            variant="contained"
                                            onClick={onClose}>Cerrar</Button>
                                        <Button
                                            size='small'
                                            variant="contained"
                                            sx={{ marginLeft: 3 }}
                                            onClick={async () => {
                                                GenerarPdf()
                                            }}
                                        >
                                            Descargar pdf
                                        </Button>
                                    </div>
                                </div>
                            );
                        },

                    });
                    mutate(["getDataHomeCajaStado", userId])
                    toast.success(`${envio.data.mens}`)
                    reset({
                        code: "",
                        phone: "",
                        monto: ""
                    })
                    setLoadP(false)
                    setAdressRecep('')
                    setNameSend('')
                    setPhoneSend('')
                    setDipSend('')
                    setQuantSend('')
                    setPhoneRecep('')
                    setNameRecep('')
                    setCodigoVerif('')
                    setDipRecep('')
                    setFormR(false)
                } else {
                    //setFormR(false)
                    setLoadP(false)
                    toast.error(`${envio.data.mens}`)


                }

            } catch (error) {
                //console.log(error)
                //setFormR(false)
                setLoadP(false)
                toast.error(`Hay un problemaddd`)

            }
        } else {

        }

    }

    useEffect(() => {

        if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
        } else {
            window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
        }
        dispatch({
            type: TITLEPAGE,
            payload: "FORMULARIO DE RECEPCION DE DINERO"
        })
    }, [])
    return (
        <>
            <CardStadoSaldo />

            {!formR ?
                <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px", }}>
                    <Grid
                        spacing={1}
                        bgcolor="backgroundColorPage"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        container
                    >

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={4}  >
                            <Box>
                                <Typography sx={{ color: "textColorTitle", textAlign: "center", display: 'none' }} variant='h5'>
                                    formulario de recepcion  de dinero
                                </Typography>
                            </Box>

                            <div style={{ width: '100%', marginTop: 15 }}>
                                <TextField
                                    label="Telefono del remitente"
                                    id="outlined-size-small"
                                    size="medium"
                                    sx={{ width: "100%" }}
                                    {...register("phone", { required: "Campo requerido", minLength: 9, maxLength: 9 })}
                                    error={!!errors?.phone}


                                />
                            </div>
                            <div style={{ width: '100%', marginTop: 15 }}>
                                <TextField
                                    label="Codigo de verificacion"
                                    id="outlined-size-small"
                                    size="medium"
                                    sx={{ width: "100%" }}
                                    {...register("code", { required: "Campo requerido", minLength: 4 })}
                                    error={!!errors?.code}


                                />
                            </div>
                            <div style={{ width: '100%', marginTop: 15 }}>
                                <TextField
                                    label="Monto a cobrar"
                                    id="outlined-size-small"
                                    size="medium"
                                    type='number'
                                    sx={{ width: "100%" }}
                                    {...register("monto", { required: "Campo requerido", minLength: 1, min: 1000 })}
                                    error={!!errors?.monto}


                                />
                            </div>
                            <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Llave secreta"
                                        variant="outlined"
                                        sx={{ width: "100%" }}
                                        {...register("llave", { required: "Campo requerido", minLength: 1 })}
                                        error={!!errors?.llave}
                                    />
                            </div>

                            <div style={{ width: '100%', marginTop: 20 }}>
                                <LoadingButton
                                    type='submit'
                                    loading={load}
                                    variant="contained"
                                    color="primary"
                                    sx={{ width: "100%" }}
                                    size="large"
                                >
                                    <span>Buscar</span>
                                </LoadingButton>

                            </div>
                        </Grid>


                    </Grid>
                </form>
                :

                <form>

                    <Grid
                        spacing={1}
                        bgcolor="backgroundColorPage"

                        container
                    >

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                            <Box>
                                <Typography sx={{ color: "textColorTitle", textAlign: "center" }} variant='h5'>
                                    Datos del remitente
                                </Typography>
                            </Box>

                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="Nombre del remitente"
                                    id="outlined-size-small-name-s"
                                    size="medium"
                                    sx={{ width: "100%" }}
                                    value={nameSend}

                                />
                            </div>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="Telefono del remitente"
                                    id="outlined-size-small"
                                    size="medium"
                                    value={phoneSend}
                                    sx={{ width: "100%" }}



                                />
                            </div>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="El DIP/Pasaporte del remitente"
                                    id="outlined-size-small"
                                    size="medium"
                                    value={dipSend}
                                    sx={{ width: "100%" }}


                                />
                            </div>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-label">Ciudad del remitente</InputLabel>
                                    <Select
                                        id="demo-simple-select-adress-r"
                                        label="Ciudad del remitente"
                                        value={adressSend}
                                    >

                                        <MenuItem value="Malabo" >Malabo</MenuItem>
                                        <MenuItem value="Bata" >Bata</MenuItem>
                                        <MenuItem value="Mongomo" >Mongomo</MenuItem>
                                        <MenuItem value="Ebibeyin" >Ebibeyin</MenuItem>
                                        <MenuItem value="Annobon" >Annobon</MenuItem>
                                        <MenuItem value="Riaba" >Riaba</MenuItem>
                                        <MenuItem value="Luba" >Luba</MenuItem>
                                        <MenuItem value="Moka" >Moka</MenuItem>
                                        <MenuItem value="Mbini" >Mbini</MenuItem>
                                        <MenuItem value="Cogo" >Cogo</MenuItem>
                                        <MenuItem value="Niefang" >Niefang</MenuItem>
                                        <MenuItem value="Akurenam" >Akurenam</MenuItem>
                                        <MenuItem value="Evinayong" >Evinayong</MenuItem>


                                        <MenuItem value="Mongomeyeng" >Mongomeyeng</MenuItem>

                                        <MenuItem value="Micomiseng" >Micomiseng</MenuItem>
                                        <MenuItem value="Anisok" >Anisok</MenuItem>
                                        <MenuItem value="Oyala" >Oyala</MenuItem>
                                        <MenuItem value="Nsork" >Nsork</MenuItem>
                                        <MenuItem value="Akonibe" >Akonibe</MenuItem>
                                        <MenuItem value="Nsok-Nzomo" >Nsok-Nzomo</MenuItem>
                                        <MenuItem value="Nkue" >Nkue</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="El monto a enviar"
                                    id="outlined-size-small"
                                    size="medium"
                                    value={quantSend}
                                    sx={{ width: "100%" }}



                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                            <Box>
                                <Typography sx={{ color: "textColorTitle", textAlign: "center" }} variant='h5'>
                                    Datos del beneficiario
                                </Typography>
                            </Box>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="Nombre del beneficiario"
                                    id="outlined-size-small"
                                    value={nameRecep}
                                    size="medium"
                                    sx={{ width: "100%" }}



                                />
                            </div>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="Telefono del beneficiario"
                                    id="outlined-size-small"
                                    size="medium"
                                    value={phoneRecep}
                                    sx={{ width: "100%" }}


                                />
                            </div>
                            <div style={{ width: '95%', marginTop: 15 }}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-label">Ciudad del beneficiario</InputLabel>
                                    <Select
                                        id="demo-simple-select-adress-r"
                                        label="Ciudad del beneficiario"
                                        value={adressRecep}
                                    >

                                        <MenuItem value="Malabo" >Malabo</MenuItem>
                                        <MenuItem value="Bata" >Bata</MenuItem>
                                        <MenuItem value="Mongomo" >Mongomo</MenuItem>
                                        <MenuItem value="Ebibeyin" >Ebibeyin</MenuItem>
                                        <MenuItem value="Annobon" >Annobon</MenuItem>
                                        <MenuItem value="Riaba" >Riaba</MenuItem>
                                        <MenuItem value="Luba" >Luba</MenuItem>
                                        <MenuItem value="Moka" >Moka</MenuItem>
                                        <MenuItem value="Mbini" >Mbini</MenuItem>
                                        <MenuItem value="Cogo" >Cogo</MenuItem>
                                        <MenuItem value="Niefang" >Niefang</MenuItem>
                                        <MenuItem value="Akurenam" >Akurenam</MenuItem>
                                        <MenuItem value="Evinayong" >Evinayong</MenuItem>


                                        <MenuItem value="Mongomeyeng" >Mongomeyeng</MenuItem>

                                        <MenuItem value="Micomiseng" >Micomiseng</MenuItem>
                                        <MenuItem value="Anisok" >Anisok</MenuItem>
                                        <MenuItem value="Oyala" >Oyala</MenuItem>
                                        <MenuItem value="Nsork" >Nsork</MenuItem>
                                        <MenuItem value="Akonibe" >Akonibe</MenuItem>
                                        <MenuItem value="Nsok-Nzomo" >Nsok-Nzomo</MenuItem>
                                        <MenuItem value="Nkue" >Nkue</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>

                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="Codigo de verificacion"
                                    id="outlined-size-small"
                                    size="medium"
                                    value={codigoVerif}
                                    sx={{ width: "100%" }}


                                />
                            </div>

                            <div style={{ width: '95%', marginTop: 15 }}>
                                <TextField
                                    label="Dip/Pasaporte del beneficiario"
                                    id="outlined-size-small"
                                    size="medium"
                                    onChange={(e) => setDipRecep(e.target.value)}
                                    value={dipRecep}
                                    sx={{ width: "100%" }}
                                    error={dipRecep ? false : true}


                                />
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <LoadingButton
                                    onClick={() => Recibir()}
                                    loading={loadP}
                                    variant="contained"
                                    sx={{ width: "100%" }}
                                    size="large"
                                >
                                    <span>Realizar el pago</span>
                                </LoadingButton>

                            </div>
                        </Grid>
                    </Grid>
                </form>


            }



        </>
    )
}


export default Recibir