import React, { useState, useContext, useEffect } from 'react'
import { Grid, Tab, Tabs, Box, InputAdornment, TextField, Autocomplete, Typography, FormControl, InputLabel, Select, MenuItem, Button, Card, CardContent } from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import AppContext from '../../contexts/ServiceContext';
import { useForm } from 'react-hook-form';
import { AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { jsPDF } from "jspdf";
import LinearProgress from '@mui/material/LinearProgress';
import { useSWRConfig } from 'swr';
import CardStadoSaldo from '../../components/cardStadoSaldo';
import { TITLEPAGE, M2U, MCMPART2, MTN, YOOMEE, paises } from '../../contexts/constantesVar';
import { set } from 'date-fns';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


function VerificarMoEx() {

    const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones, porcentage, AxiosConfigsToken, monedaValor, ObtenervalorMoneda } = useContext(AppContext)
    const { mutate } = useSWRConfig()
    //el useForm de react form hook
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm();

    const [loadM, setLoadM] = useState(false)//estado para activar el spinner del boton submit
    const [load, setLoad] = useState(false)//estado para activar el spinner del boton submit

    const [idTransaction, setIdTransaction] = useState('')
    const [reference, setReference] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [receiverName, setReceiverName] = useState('')
    const [receiverDocument, setReceiverDocument] = useState('')
    const [fecha, setFecha] = useState('')
    const [envioData, setEnvioData] = useState([])

    const [validarTransaciion, setValidarTransaciion] = useState(false)
    const [verificarTransaciion, setVerificarTransaciion] = useState(false)
    const [spinnerVerificar, setSpinnerVerificar] = useState(false)
    const [spinnerValidar, setSpinnerValidar] = useState(false)

    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data) => {
        console.log(data)
        setSpinnerVerificar(true)
        setVerificarTransaciion(false)
        try {
            const recibir = await AxiosConfigsToken({ url: `/me/getTransactionMoEx`, data, method: "post" })

            console.log(recibir)

            if (recibir.data.verificar) {
                if (recibir.data.data.error.Id.$value === 0) {
                    console.log(recibir.data.data)
                    setReceiverName(recibir.data.data.transaction.Receiver.$value)
                    setEnvioData(recibir.data.data.transaction)
                    //setReceiverDocument(recibir.data.data.transaction.ReceiverIdDocumentNumber.$value)
                    setVerificarTransaciion(false)
                    setSpinnerVerificar(false)
                    handleClickOpen()
                } else {
                    toast.error(`${recibir.data.data.error.Description.$value}`)
                    setVerificarTransaciion(false)
                    setSpinnerVerificar(false)
                }

            } else {

                setVerificarTransaciion(false)
                setSpinnerVerificar(false)

            }

        } catch (error) {
            setVerificarTransaciion(false)
            setSpinnerVerificar(false)
        }
    }


    useEffect(() => {
        ObtenervalorMoneda()
        if (JSON.parse(window.localStorage.getItem("enableTAdmins"))) {
        } else {
            window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
        }
        dispatch({
            type: TITLEPAGE,
            payload: "Enviar a nivel internacional"
        })
    }, [])

    return (
        <>
            {valideLogin ?
                <>
                    <div >

                        {envioData.TransactionId ?
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >

                                <DialogContent sx={{ paddingInline: 1, paddingTop: 4 }}>
                                    <div className='container-dialog-confirm' >

                                        <div id='facturaSend' style={{ marginLeft: 10, marginTop: 0 }} >

                                            <h3 style={{ marginBlock: -7 }}>Verificacion MoEx</h3>

                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Fecha de envio:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{ }</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>El Id del envio:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envioData.TransactionId.$value}</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Referencia del envio:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envioData.Reference.$value}</span></p>

                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Oficina de pago:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>{envioData.PaymentBranchName.$value}</span></p>

                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Oficina de pago tel.:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>
                                                {envioData.PaymentBranchPhone.$value}</span></p>

                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Oficina de pago direc.:</span> <span style={{ fontWeight: "700", color: '#000000', fontSize: 12 }}>
                                                {envioData.PaymentBranchAddress.$value}</span></p>
                                            <h4 style={{ marginBlock: -6, marginTop: 3, color: '#000000' }}>DATOS DEL REMITENTE</h4>

                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Nombre del remitente:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {envioData.Sender.$value}</span></p>

                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>DIP/Pass del remitente:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {envioData.SenderIdDocumentNumber.$value}</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Cantidad enviada de XAF:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {(Number(envioData.AmountSent.$value) * Number(monedaValor)).toLocaleString("es-GQ")} XAF</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Cantidad enviada de {envioData.CurrencySent.$value}:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {Number(envioData.AmountSent.$value).toLocaleString("es-GQ")} {envioData.CurrencySent.$value}</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Cantidad enviada de {envioData.CurrencyToPay.$value}:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {Number(envioData.AmountToPay.$value).toLocaleString("es-GQ")} {envioData.CurrencyToPay.$value}</span></p>
                                            <h4 style={{ marginBlock: -6, marginTop: 3, color: '#000000' }}>DATOS DEL BENEFICIARIO</h4>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Pais del receptor:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {envioData.ReceiverCountry.$value}</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Ciudad de recepcion:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {envioData.ReceiverCity.$value}</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Nombre del receptor:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>
                                                {envioData.Receiver.$value}</span></p>
                                            <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Tel. del receptor:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envioData.ReceiverPhone.$value}</span></p>

                                            {envioData.BankDeposit.$value ?
                                                <>

                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Nombre del banco:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envioData.BankName.$value}</span></p>


                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Numero de cuenta:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envioData.BankAccount.$value}</span></p>

                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Direccion de cuenta:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>{envioData.BankAddress.$value}</span></p>


                                                </>
                                                :
                                                <>
                                                    <p style={{ marginBlock: 1 }}><span style={{ fontSize: 12, fontWeight: '700', color: '#000000' }}>Tipo de transacion:</span> <span style={{ fontWeight: "700", fontSize: 14, color: '#000000' }}>Transaccion normal (No bancaria)</span></p>
                                                </>
                                            }

                                        </div>

                                        <div style={{ marginLeft: 10,display:'none' }} >

                                            <Button
                                                size='small'
                                                variant="contained"
                                                color='error'
                                                onClick={handleClose}>Cerrar
                                            </Button>
                                            <Button
                                                size='small'
                                                variant="contained"
                                                sx={{ marginLeft: 3 }}
                                                onClick={async () => {
                                                }}
                                            >
                                                Descargar pdf
                                            </Button>
                                        </div>
                                    </div>
                                </DialogContent>


                            </Dialog>
                            :
                            <></>
                        }
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "20px" }}>
                        <Grid
                            spacing={1}
                            bgcolor="backgroundColorPage"
                            sx={{ display: 'flex', justifyContent: "center" }}
                            container
                        >

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >


                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Referencia de la transaccion"
                                        id="outlined-size-small-name-s"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        {...register("reference", { required: "Campo requerido" })}
                                        error={!!errors?.reference}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 15 }}>
                                    <TextField
                                        label="Llave secreta"
                                        id="outlined-size-small-name-s"
                                        size="medium"
                                        sx={{ width: "100%" }}
                                        {...register("llaveSecreta", { required: "Campo requerido" })}
                                        error={!!errors?.llaveSecreta}

                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 20, justifyContent: 'space-around', display: 'flex' }}>
                                    <LoadingButton
                                        loading={spinnerVerificar}
                                        variant="contained"
                                        //color="success"
                                        sx={{ width: "100%" }}
                                        size="large"
                                        type='submit'
                                    >
                                        <span>Verificar transaccion y pagar</span>
                                    </LoadingButton>
                                </div>
                            </Grid>

                        </Grid>
                    </form>
                </>
                :
                <></>
            }

        </>
    )
}


export default VerificarMoEx