import axios from "axios"
import axiosConfigs from "../../axiosConfig"



export const GetNigeriaBank = async () => {
    const res = await axiosConfigs.get('/nigeria_banks')
    const data = res.data.data.data
    console.log(data)
    return data
}
