import * as React from 'react';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Add, Home, PersonAddAlt1 } from '@mui/icons-material';
import { PeopleAlt } from '@mui/icons-material';
import { Sort } from '@mui/icons-material';
import { Sync } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { HouseSharp } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';
import FeedIcon from '@mui/icons-material/Feed';
import { Box, Button } from '@mui/material';
import { ID_USER, NAME_USER, PHONE_USER, TYPE_USER, VALIDE_USER } from '../../contexts/constantesVar';
import AppContext from '../../contexts/ServiceContext';

const drawerWidth = 180;

const listIconStyle2 = {
    color: "#00113d",
    fontSize: '27px',
    fontWeight: "600px"
}

const listTextStyle = {
    fontWeight: "900",
    color: "textColorTitle2"
}



function DrawerListt({ toggleDrawer }) {
    const { pathname, state, key } = useLocation();

    const { userId, dispatch, typeUser, valideLogin, userName } = React.useContext(AppContext)

    const CloseSesion = () => {
        window.localStorage.setItem("enableTAdmins", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '', accI: [] }))

        dispatch({
            type: VALIDE_USER,
            payload: false
        })
        dispatch({
            type: NAME_USER,
            payload: ""
        })

        dispatch({
            type: ID_USER,
            payload: ""
        })


        dispatch({
            type: TYPE_USER,
            payload: ""
        })
        dispatch({
            type: PHONE_USER,
            payload: ""
        })

        window.localStorage.setItem("tokenGnop", "")
        window.localStorage.setItem("qsaw", "")

    }
    const listePath = [
        { text: 'Inicio', link: "/" },
        { text: 'Enviar nacio.', link: "/enviar" },
        { text: 'Enviar inter.', link: "/inter_enviar" },
        //{ text: 'Verificar Mo.Ex', link: "/verificarMoEx" },
        { text: 'Recibir', link: "/recibir" },
        //{ text: 'Recibir por MoEx', link: "/recibirMoEx" },
        { text: 'Transacciones', link: "/transaccion/1" },
        { text: 'Flujo de saldo', link: "/flujo/1" },
        { text: 'Info de la caja', link: `/caja_master_info/${userId}` },
        //{ text: 'Cambiar contrasena', link: "/cambiar_password" },
    ]
    return (
        <div>
            {valideLogin ?
                <div>
                    <Toolbar title="TITLE" >
                        <Box sx={{
                            marginTop: 3,
                            flexDirection: 'column'
                        }}>
                            {

                                <img
                                    src={'https://res.cloudinary.com/mumbex/image/upload/v1713777064/rbu7lzd382j4lpm5txzi.png'}
                                    alt={"globals"}
                                    loading="lazy"
                                    width={130}

                                />
                            }

                        </Box>


                    </Toolbar>

                    <List>
                        {listePath.map((menu, index) => (
                            <>
                                {pathname.slice(0, 4) == menu.link.slice(0, 4) ?
                                    <ListItem key={menu.text} disablePadding divider={true} sx={{ color: "#212121",bgcolor: "#eee", fontSize: '30px', fontWeight: "800px" }}>
                                        <ListItemButton component={NavLink} to={menu.link} onClick={toggleDrawer} >

                                            <ListItemText primary={menu.text} sx={listIconStyle2} />
                                        </ListItemButton>
                                    </ListItem>
                                    :
                                    <ListItem key={menu.text} disablePadding divider={true} sx={{ color: "#212121", fontSize: '30px', fontWeight: "800px" }}>
                                        <ListItemButton component={NavLink} to={menu.link} onClick={toggleDrawer} >

                                            <ListItemText primary={menu.text} sx={listTextStyle} />
                                        </ListItemButton>
                                    </ListItem>
                                }
                            </>

                        ))}
                    </List>
                    <List>
                        <ListItem disablePadding divider={true} sx={{ color: "#212121", marginTop: 3, fontSize: '30px', bgcolor: "#ff9800", fontWeight: "800px" }}>
                            <ListItemButton onClick={() => {
                                CloseSesion()
                            }} >

                                <ListItemText primary={'Cerra sesion'} sx={{ color: '#212121' }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
                :
                <div></div>
            }
        </div>
    )
}

export default DrawerListt